<template>
  <div
    class="flex flex-col tablet:flex-row gap-4 desktop:gap-8 justify-center"
  >
    <template
      v-for="social in socials"
    >
      <div
        class="bg-white rounded shadow-lg flex flex-row tablet:flex-col gap-2 mobile:gap-6 desktop:gap-8 items-center p-4 desktop:p-6 flex-1"
      >
        <font-awesome-icon
          :icon="social.icon"
          class="text-primary aspect-square h-[4em] w-[4m] text-[3em] mobile:text-[4em] p-2"
        />
        <span
          class="h3 hidden laptop:block text-slate-900"
        >
          {{ social.title }}
        </span>
        <span
          class="tablet:hidden desktop:block text-center text-slate-900"
        >
          {{ social.text }}
        </span>
        <NuxtLink
          :to="social.href"
          target="_blank"
          class="ml-2 mobile:ml-auto tablet:ml-0 text-center rounded bg-primary hover:bg-primary-highlighted active:bg-primary-selected
            text-white px-4 py-2 mobile:max-tablet:min-w-[8em] tablet:w-full desktop:mt-auto font-semibold"
        >
          <span
            class="hidden mobile:block tablet:max-laptop:hidden"
          >
            {{ social.label }}
          </span>
          <div
            class="block mobile:hidden tablet:max-laptop:block"
          >
            <font-awesome-icon
              icon="arrow-right"
            />
          </div>
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const config = useRuntimeConfig()
const {t} = useI18n()

const socials = [
  {
    title: t("jc.view.index.social.cards.twitter.title"),
    text: t("jc.view.index.social.cards.twitter.text"),
    icon: {prefix: "fab", iconName: "twitter"},
    label: t("jc.view.index.social.cards.twitter.label"),
    href: config.public.twitterUrl
  },
  {
    title: t("jc.view.index.social.cards.youtube.title"),
    text: t("jc.view.index.social.cards.youtube.text"),
    icon: {prefix: "fab", iconName: "youtube"},
    label: t("jc.view.index.social.cards.youtube.label"),
    href: config.public.youtubeUrl
  },
  {
    title: t("jc.view.index.social.cards.discord.title"),
    text: t("jc.view.index.social.cards.discord.text"),
    icon: {prefix: "fab", iconName: "discord"},
    label: t("jc.view.index.social.cards.discord.label"),
    href: config.public.discordUrl
  },
  {
    title: t("jc.view.index.social.cards.instagram.title"),
    text: t("jc.view.index.social.cards.instagram.text"),
    icon: {prefix: "fab", iconName: "instagram"},
    label: t("jc.view.index.social.cards.instagram.label"),
    href: config.public.instagramUrl
  },
  {
    title: t("jc.view.index.social.cards.twitch.title"),
    text: t("jc.view.index.social.cards.twitch.text"),
    icon: {prefix: "fab", iconName: "twitch"},
    label: t("jc.view.index.social.cards.twitch.label"),
    href: config.public.twitchUrl
  },
]
</script>
