<template>
  <div
    class="aspect-video"
  >
    <div
      class="relative aspect-video rounded overflow-hidden"
    >
      <NuxtPicture
        :src="props.image.src"
        :alt="props.image.alt"
        :title="props.image.title"
        sizes="100vw desktop:500px"
        quality="100"
        loading="lazy"
      />
      <NuxtLink
        :to="href"
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
          transition ease-in-out hover:scale-110 duration-300
          bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
          text-center rounded-full p-[0.75em]"
        :title="t('jc.component.videoPreviewLink.link.title')"
        target="_blank"
      >
        <font-awesome-icon
          icon="play"
          class="relative text-[1.75em] aspect-square left-[4%]"
        />
      </NuxtLink>
    </div>
    <div
      class="w-2/3 laptop:w-1/3 h-[1.7rem] mx-auto relative"
    >
      <NuxtLink
        :to="href"
        class="inline-block relative bottom-[50%] w-full
        bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
        text-center rounded py-2 px-3 font-semibold"
        :title="t('jc.component.videoPreviewLink.link.title')"
      >
        {{ props.label }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Image } from "~/utils/types";

const { t } = useI18n()

const props = defineProps<{
  image: Image
  href: string
  label: string
}>()

</script>

<style scoped lang="postcss">
:deep(img) {
  @apply w-full h-full object-cover
}
</style>
