<template>
  <div
    class="rounded-md overflow-hidden shadow-lg border border-gray-200 bg-white
      grid grid-cols-1 desktop:grid-cols-2"
  >
    <span
      class="bg-primary desktop:col-span-2
        min-h-[10px] min-w-[10px] laptop:min-h-[15px] laptop:min-w-[15px]"
    />

    <h2
      class="text-slate-900 text-center desktop:col-span-2 mx-8 desktop:mx-16 mt-6"
    >
      {{ t('jc.view.index.trailer.title') }}
    </h2>

    <p
      class="text-slate-900 text-center desktop:col-span-2 mx-8 desktop:mx-16 mt-4"
    >
      {{ t('jc.view.index.trailer.text') }}
    </p>

    <IconSection
      :sections="sections"
      class="ml-8 desktop:ml-16 mr-8 desktop:mr-2 mt-8 mb-6"
    />

    <VideoPreviewLink
      :image="{ src: 'images/component/trailerCard/teaser_preview.png' }"
      :href="config.public.youtubeTrailerUrl"
      :label="t('jc.view.index.trailer.button.label')"
      class="ml-8 desktop:ml-2 mr-8 desktop:mr-16 mt-8 mb-6"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const config = useRuntimeConfig()

const sections = [
  {
    icon: 'gamepad-modern',
    title: t('jc.view.index.trailer.highlightItems.fun.title'),
    text: t('jc.view.index.trailer.highlightItems.fun.text')
  },
  {
    icon: 'lightbulb',
    title: t('jc.view.index.trailer.highlightItems.innovation.title'),
    text: t('jc.view.index.trailer.highlightItems.innovation.text')
  },
  {
    icon: 'handshake',
    title: t('jc.view.index.trailer.highlightItems.community.title'),
    text: t('jc.view.index.trailer.highlightItems.community.text')
  },
]
</script>
